import { SetStateAction, useEffect, useState } from 'react';
import { useClient } from './useClient';
import { showMessage } from '../alert/model/alertSlice';
import { useDispatch } from 'react-redux';

export const useLoader = <T>(path: string) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<T>();
  const client = useClient();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data && loading) setLoading(false);
    if (loading) {
      const controller = new AbortController();

      client
        .get<T>(path, {
          signal: controller.signal,
        })
        .then(({ data }) => {
          setLoading(false);
          setData(data);
          console.log('DATA', data);
        })
        .catch(err => {
          if (err instanceof Error && err.message !== 'canceled') {
            setLoading(false);
            setError(true);
            dispatch(showMessage({ severity: 'error', message: err.message }));
          }
        });

      return () => {
        controller.abort();
      };
    }
  }, []);

  return { loading, error, data };
};
