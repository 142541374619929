import { Button } from '@mui/material';
import clsx from 'clsx';
import { IButton } from '../types';

export const CustomButton = ({ className, content, onClick, style, type }: IButton) => {
  return (
    <Button className={clsx(className)} variant={style} onClick={onClick} type={type || 'button'}>
      {content}
    </Button>
  );
};
