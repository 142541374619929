import { TextField } from '@mui/material';
import { ChangeEventHandler } from 'react';

export const CustomInput = ({
  id,
  label,
  value,
  onChange,
  autoFocus,
  required,
}: {
  id: string;
  label: string;
  value: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  autoFocus?: boolean;
  required?: boolean;
}) => {
  return (
    <TextField
      required={required ?? false}
      autoFocus={autoFocus ?? false}
      margin="normal"
      fullWidth
      id={id}
      label={label}
      // name="repo id"

      value={value}
      onChange={onChange}
    />
  );
};
