import { Typography, Tabs, Tab, Box, Grid } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import AddProjectPlatform from '../../features/platform/ui/AddPlatform';
import GetProjectData from '../../entities/project/api/GetProjectData';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import {
  IPipeline,
  IPlatform,
  IProject,
  ISetPipeline,
  ISetPlatforms,
  ISetProject,
  getProjects,
  selectProjectById,
  setPipeline,
  setPlatforms,
  setProject,
} from '../../entities/project/model/projectSlice';
import { useParams } from 'react-router-dom';
import { Loading } from '../../shared/ui/loading/Loading';
import DeleteProjectPlatform from '../../features/platform/ui/DeletePlatform';
import { showMessage } from '../../shared/alert/model/alertSlice';
import { DeployProjectTab } from '../../widgets/project';
import { useLoader } from '../../shared/api/useLoader';
import { Project } from '../../entities/project';
import AppPage from '../../shared/ui/page/AppPage';
import { Link } from '@mui/material';
import { selectUser } from '../../entities/auth/model/authSlice';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  sx?: any;
}
const TabPanel = (props: TabPanelProps): JSX.Element => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3, height: '100%' }}>{children}</Box>}
    </Box>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const ProjectTabs = (): JSX.Element => {
  const [value, setValue] = useState(0);
  const { projectId } = useParams();
  const project = useSelector<RootState, IProject>(state => selectProjectById(state, projectId));
  const user = useSelector(selectUser);
  const isAdmin = user?.role.name === 'admin';
  const dispatch = useDispatch();
  const { loading, error, data } = useLoader<IProject>(`/projects/${projectId}`);
  useEffect(() => {
    if (data) dispatch(setProject({ project: data, projectId: projectId! }));
  }, [loading, data]);

  if (loading) return <Loading />;
  if (error || !data) return <Box>Error</Box>;

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const link = project?.pipeline?.url.split('/').slice(0, -1).join('/');
  return (
    <AppPage title="Project">
      <Box sx={{ width: '100%', height: '80%' }}>
        <Box>
          <Typography variant="h6">{project?.name}</Typography>
        </Box>
        {link && (
          <Box>
            {'piplines: '}
            <Link href={link} target="_blank">
              {link}
            </Link>
          </Box>
        )}
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="tabs" variant="scrollable">
            <Tab label="Deploy" {...a11yProps(0)} />
            {isAdmin && <Tab label="Add platform/service" {...a11yProps(1)} />}
            {isAdmin && <Tab label="Delete platform/service" {...a11yProps(2)} />}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} sx={{ height: '90%' }}>
          <DeployProjectTab project={project} />
        </TabPanel>
        {isAdmin && (
          <TabPanel value={value} index={1} sx={{ height: '90%' }}>
            <AddProjectPlatform project={project} />
          </TabPanel>
        )}
        {isAdmin && (
          <TabPanel value={value} index={2} sx={{ height: '90%' }}>
            <DeleteProjectPlatform project={project} />
          </TabPanel>
        )}
      </Box>
    </AppPage>
  );
};

export default ProjectTabs;
