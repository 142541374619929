import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

type TAlert = 'error' | 'warning' | 'info' | 'success';

interface IAlertInitialState {
  show: boolean;
  message: string;
  severity: TAlert;
}

const initialState: IAlertInitialState = {
  show: false,
  message: '',
  severity: 'error',
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.show = true;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
    },
    hideMessage: state => {
      state.show = false;
    },
  },
});

export default alertSlice.reducer;

export const { showMessage, hideMessage } = alertSlice.actions;

export const selectSeverity = (state: RootState) => state.alert.severity;
export const selectMessage = (state: RootState) => state.alert.message;
export const selectShow = (state: RootState) => state.alert.show;
