import { useNavigate } from 'react-router-dom';
import SharedComponents from '../../shared/ui';
import cls from './projectPage.module.scss';
import clsx from 'clsx';
import { IProject, getProjects, selectProjects } from '../../entities/project/model/projectSlice';
import api from '../../shared/api';
import { useDispatch, useSelector } from 'react-redux';
import { ProjectsTable } from '../../widgets/project';
import AppPage from '../../shared/ui/page/AppPage';
import { selectUser } from '../../entities/auth/model/authSlice';
import { useEffect } from 'react';
import { Box } from '@mui/material';

const { useLoader } = api;
const { CustomButton, Loading } = SharedComponents;

export const ProjectsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);

  const user = useSelector(selectUser);
  const isAdmin = user?.role.name === 'admin';
  const { loading, error, data } = useLoader<IProject[]>('/projects');
  useEffect(() => {
    if (data) dispatch(getProjects(data));
  }, [loading, data]);
  if (loading) <Loading />;
  if (error || !data) return <Box>Error</Box>;

  return (
    <AppPage title="Project table">
      <>
        {isAdmin && (
          <CustomButton style="text" onClick={() => navigate('/projects/add')} content="Add project" />
        )}
        <ProjectsTable projects={projects} isAdmin={isAdmin} />
      </>
    </AppPage>
  );
};
