import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, TextField } from '@mui/material';

import { IProject, setPlatforms, setServices } from '../../../entities/project/model/projectSlice';
import { selectUser } from '../../../entities/auth/model/authSlice';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import { useClient } from '../../../shared/api/useClient';

interface IAddPlatformsBody {
  projectId: string;
  platforms: string[];
}

const InputElement = ({
  id,
  label,
  inputValue,
  setInputValue,
  onClickHandler,
}: {
  id: string;
  label: string;
  inputValue: string;
  setInputValue: (value: React.SetStateAction<string>) => void;
  onClickHandler: () => Promise<void>;
}) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8} alignItems="center" justifyContent="center">
        <TextField
          id={id}
          label={label}
          value={inputValue}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setInputValue(event.target.value);
          }}
          fullWidth
        />
      </Grid>

      <Grid item xs={2} alignItems="center" justifyContent="center">
        <Button onClick={onClickHandler}>Add platform</Button>
      </Grid>
    </Grid>
  );
};

const AddProjectPlatform = ({ project }: { project: IProject }): JSX.Element => {
  const [inputPlatform, setInputPlatform] = useState('');
  const [inputService, setInputService] = useState('');
  const client = useClient();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  if (user?.role.name !== 'admin') return <Box>Not permitted</Box>;

  const onAddPlatformClick = async () => {
    const sendPlatform = async () => {
      try {
        if (inputPlatform) {
          const { data } = await client.post('/platforms', {
            projectId: project.id,
            platform: inputPlatform,
          });

          dispatch(setPlatforms({ projectId: project.id, platforms: data }));
          setInputPlatform('');
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await sendPlatform();
  };

  const onAddServiceClick = async () => {
    const sendService = async () => {
      try {
        if (inputService) {
          const { data } = await client.post('/services', {
            projectId: project.id,
            service: inputService,
          });

          dispatch(setServices({ projectId: project.id, services: data }));
          setInputService('');
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await sendService();
  };

  const addPlatform = (
    <InputElement
      id="platform"
      label="Platform"
      inputValue={inputPlatform}
      setInputValue={setInputPlatform}
      onClickHandler={onAddPlatformClick}
    />
  );

  const addService = (
    <InputElement
      id="service"
      label="Service"
      inputValue={inputService}
      setInputValue={setInputService}
      onClickHandler={onAddServiceClick}
    />
  );

  return (
    <>
      <Box sx={{ marginBottom: 2 }}>{user?.role.name === 'admin' ? addPlatform : null}</Box>
      <Box>{user?.role.name === 'admin' ? addService : null}</Box>
    </>
  );
};

export default AddProjectPlatform;
