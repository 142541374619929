import { Box } from '@mui/material';
import { FormEvent, useState } from 'react';
import SharedComponents from '../../../shared/ui';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { changeStatus } from '../../../entities/project/model/projectSlice';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import clsx from 'clsx';
import cls from './project.module.scss';
import useClient from '../../../shared/api/useClient';

interface IAddProjectRequest {
  repoId: number;
  branch: string;
}

export const AddProject = () => {
  const [branch, setBranch] = useState('');
  const [repoId, setRepoId] = useState('');

  const dispatch = useDispatch();
  const client = useClient();
  const navigate = useNavigate();

  const onAddProjectSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!(repoId && branch)) {
        dispatch(showMessage({ severity: 'error', message: 'All required fields are not filled in' }));
        return;
      }

      const body: IAddProjectRequest = { repoId: +repoId, branch };

      await client.post('/projects', body);
      setRepoId('');
      setBranch('');
      dispatch(showMessage({ severity: 'success', message: 'Project added' }));
      dispatch(changeStatus());
      navigate('/projects');
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  return (
    <Box className={clsx(cls.add_project)} component="form" onSubmit={onAddProjectSubmit} noValidate>
      <SharedComponents.CustomInput
        id="project-id"
        label="Project ID"
        value={repoId}
        onChange={event => setRepoId(event.target.value)}
        autoFocus
        required
      />
      <SharedComponents.CustomInput
        id="branch"
        label="Branch"
        value={branch}
        onChange={event => setBranch(event.target.value)}
        required
      />

      <SharedComponents.CustomButton content="Add" style="contained" type="submit" />
    </Box>
  );
};
