import { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UserTable from '../../../widgets/user/ui/UserTable';
import AppPage from '../../../shared/ui/page/AppPage';
import { TAuthUser, selectToken, selectUser } from '../../../entities/auth/model/authSlice';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../../shared/api/useLoader';
import { IRole } from '../../../features/user/ui/AddUser';
import { IUser, IUsersState, getUsers, selectUsers } from '../../../entities/user/model/userSlice';
import { Loading } from '../../../shared/ui/loading/Loading';
// import useApi from '../../../shared/api/useClient';

interface IUserData {
  id: string;
  login: string;
  role: IRole;
}

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const isAdmin = user?.role.name === 'admin';

  const users = useSelector(selectUsers);

  const { loading, data, error } = useLoader<IUserData[]>('/users');

  useEffect(() => {
    if (data) dispatch(getUsers({ users: data.map(d => ({ id: d.id, login: d.login, role: d.role.name })) }));
  }, [data, loading]);

  if (error) return <Box>Error</Box>;
  if (loading) return <Loading />;
  if (!isAdmin) return <Box>Not permitted</Box>;

  return (
    <AppPage title="User table">
      <>
        <Button onClick={() => navigate('/users/add')}>Add user</Button>
        <br />
        <UserTable rows={users} />
      </>
    </AppPage>
  );
};

export default Users;
