import { Route, Routes } from 'react-router-dom';
import Users from '../pages/users/ui/Users';
import Platform from '../features/platform/Platform';
import SignIn from '../features/auth/SigninForm';
import Profile from '../pages/users/ui/Profile';
import EditProfile from '../features/user/ui/EditProfile';
import AddUser from '../features/user/ui/AddUser';

import ProjectTabs from '../pages/projects/ProjectTabs';
import { ProjectsPage } from '../pages/projects/ProjectsPage';
import { AddProject } from '../features/project/ui/AddProject';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/edit" element={<EditProfile />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/projects">
        <Route path="" element={<ProjectsPage />} />
        <Route path=":projectId" element={<ProjectTabs />} />
        <Route path="add" element={<AddProject />} />
      </Route>
      <Route path="/users" element={<Users />} />
      <Route path="/users/add" element={<AddUser />} />
      <Route path="/platforms" element={<Platform />} />
    </Routes>
  );
};

export default AppRouter;
