import { useState } from 'react';
import { Box, TextField, Button } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import AppPage from '../../../shared/ui/page/AppPage';
import useClient from '../../../shared/api/useClient';

interface IEditProfileBody {
  login?: string;
  password?: string;
  privateToken?: string;
}

const EditProfile = (): JSX.Element | null => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [privateToken, setToken] = useState('');

  const client = useClient();
  const dispatch = useDispatch();

  const onEditSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const body: IEditProfileBody = {};
    if (login) body.login = login;
    if (password) body.password = password;
    if (privateToken) body.privateToken = privateToken;

    try {
      await client.patch('/users', body);
      setLogin('');
      setPassword('');
      setToken('');
      dispatch(showMessage({ severity: 'success', message: 'Profile updated' }));
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  return (
    <AppPage title="Edit Profile">
      <>
        <Box component="form" onSubmit={onEditSubmit} noValidate>
          <TextField
            margin="normal"
            fullWidth
            id="login"
            label="Login"
            name="login"
            autoComplete="login"
            autoFocus
            value={login}
            onChange={event => setLogin(event.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="password"
            label="Password"
            id="password"
            value={password}
            onChange={event => setPassword(event.target.value)}
          />
          <TextField
            margin="normal"
            fullWidth
            name="private_token"
            label="Private token"
            id="private_token"
            autoComplete="private token"
            autoFocus
            value={privateToken}
            onChange={event => setToken(event.target.value)}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            Edit
          </Button>
        </Box>
      </>
    </AppPage>
  );
};

export default EditProfile;
