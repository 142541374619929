import { useEffect, useState } from 'react';
import { IProject, setPipeline } from '../../../entities/project/model/projectSlice';
import { Box, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import { StageSelector } from '../../../features/deploy';
import { ItemSelector } from '../../../features/platform/ui/SelectPlatform';
import { DeployProjectButton } from '../../../features/project';
import clsx from 'clsx';
import cls from './deployProject.module.scss';
import useClient from '../../../shared/api/useClient';
import { selectUser } from '../../../entities/auth';

export const DeployProjectTab = ({ project }: { project: IProject }): JSX.Element => {
  const client = useClient();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const isAdmin = user?.role.name === 'admin';

  const platforms = project?.platforms ?? [];
  const services = project?.services?.map((s, i) => ({ id: i.toString(), name: s })) ?? [];

  const [checkedPlatforms, setCheckedPlatforms] = useState<{ id: string; name: string; checked: boolean }[]>(
    [],
  );

  const [checkedServices, setCheckedServices] = useState<{ id: string; name: string; checked: boolean }[]>(
    [],
  );
  useEffect(() => {
    const platforms = (project?.platforms ?? []).map(p => ({ id: p.id, checked: false, name: p.name }));
    setCheckedPlatforms(platforms);
    const services = project?.services?.map((s, i) => ({ id: i.toString(), name: s })) ?? [];
    setCheckedServices(services.map(s => ({ id: s.id, checked: false, name: s.name })));
  }, [project]);

  const PlatformSelectorElement = (
    <ItemSelector
      items={platforms}
      checked={checkedPlatforms}
      setChecked={setCheckedPlatforms}
      label="Select platforms"
    />
  );

  const ServicesSelectorElement = (
    <ItemSelector
      items={services}
      checked={checkedServices}
      setChecked={setCheckedServices}
      label="Select services"
    />
  );

  const radioOptions = ['development', 'production'];
  const [radioChecked, setRadioChecked] = useState(radioOptions[0]);
  const DeployStageSelectorElement = (
    <StageSelector
      radioOptions={radioOptions}
      radioChecked={radioChecked}
      setRadioChecked={setRadioChecked}
    />
  );

  const onDeployClick = async () => {
    const projectId = project.id;
    const platforms = checkedPlatforms
      .filter(c => c.checked)
      .map(c => c.name)
      .join(',');
    const services = checkedServices
      .filter(s => s.checked)
      .map(s => s.name)
      .join(',');

    const stage = radioChecked;

    const sendDeployRequest = async () => {
      try {
        if (platforms) {
          await client.post(`/pipelines/${projectId}`, { platforms, stage, services });

          // dispatch(setPipeline({ projectId: project.id, pipeline: null }));
          dispatch(showMessage({ severity: 'success', message: 'success' }));
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await sendDeployRequest();
  };

  return (
    <Box sx={{ height: '100%' }}>
      <Grid container spacing={2} sx={{ height: '80%' }}>
        <Grid item xs={6} sx={{ height: '100%' }}>
          <Box
            sx={{
              border: 'solid',
              padding: 1,
              borderWidth: 1,
              borderColor: '#3388e8',
              borderRadius: 1,
              height: '100%',
            }}
          >
            {PlatformSelectorElement}
          </Box>
        </Grid>
        {!!services.length && (
          <Grid item xs={6} sx={{ height: '100%' }}>
            <Box
              sx={{
                border: 'solid',
                padding: 1,
                borderWidth: 1,
                borderColor: '#3388e8',
                borderRadius: 1,
                height: '100%',
              }}
            >
              {ServicesSelectorElement}
            </Box>
          </Grid>
        )}
      </Grid>
      {isAdmin && <Box sx={{ marginTop: 2, marginBottom: 2 }}>{DeployStageSelectorElement}</Box>}
      <DeployProjectButton onClick={onDeployClick} />
    </Box>
  );
};
