import clsx from 'clsx';
import { TableRow, TableHead, TableContainer, TableCell, TableBody, Table } from '@mui/material';
import { ITable, THeader, TRow } from '../types';

const returnRows = (data: TRow[]) => {
  const rows: JSX.Element[] = [];
  data.forEach((row, index) =>
    rows.push(
      <TableRow key={index}>
        {row.map((d, index) => (
          <TableCell key={index} align={d?.align || 'inherit'}>
            {d.content}
          </TableCell>
        ))}
      </TableRow>,
    ),
  );

  return rows;
};
const returnHeaders = (data: THeader) => {
  return data.map((d, index) => (
    <TableCell key={index} align={d?.align || 'inherit'}>
      {d.name}
    </TableCell>
  ));
};

export const CustomTable = ({ className, container, headers, rows }: ITable) => {
  return (
    <TableContainer component={container} sx={{ height: '90%' }}>
      <Table className={clsx(className)} stickyHeader>
        <TableHead>
          <TableRow>{returnHeaders(headers)}</TableRow>
        </TableHead>
        <TableBody>{returnRows(rows)}</TableBody>
      </Table>
    </TableContainer>
  );
};
