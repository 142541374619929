import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import { IPlatform } from '../../../entities/project/model/projectSlice';

interface IItemOption {
  id: string;
  checked: boolean;
  name: string;
}

export const ItemSelector = ({
  items,
  checked,
  setChecked,
  label,
}: {
  items: { id: string; name: string }[];
  checked: IItemOption[];
  setChecked: React.Dispatch<any>;
  label: string;
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newState = [...checked];
    const flag = newState.find(c => c.id === event.target.name);
    if (flag) {
      flag.checked = !flag.checked;
      setChecked(newState);
    }
  };

  const list = items.map(p => (
    <FormControlLabel
      control={
        <Checkbox checked={!!checked.find(c => c.id === p.id)?.checked} onChange={handleChange} name={p.id} />
      }
      key={p.id}
      label={p.name}
    />
  ));

  return (
    <FormControl sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
      <FormLabel>{label}</FormLabel>
      <FormGroup>{list}</FormGroup>
    </FormControl>
  );
};
