import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';

import { DeleteForever } from '@mui/icons-material';

import { IProject, setPlatforms, setServices } from '../../../entities/project/model/projectSlice';
import { selectUser } from '../../../entities/auth/model/authSlice';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import { useClient } from '../../../shared/api/useClient';

interface IAddPlatformsBody {
  projectId: string;
  platforms: string[];
}

const DeleteProjectPlatform = ({ project }: { project: IProject }): JSX.Element => {
  const client = useClient();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);

  const isAdmin = user?.role.name === 'admin';

  if (!isAdmin) return <Box>Not permitted</Box>;

  const onDeletePlatformClick = async (platformId: string) => {
    const deletePlatform = async () => {
      try {
        const { data } = await client.delete('/platforms', { params: { platformId } });

        dispatch(setPlatforms({ projectId: project.id, platforms: data }));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await deletePlatform();
  };

  const onDeleteServiceClick = async (service: string) => {
    const deleteService = async () => {
      try {
        const { data } = await client.delete('/services', { params: { service } });

        dispatch(setServices({ projectId: project.id, services: data }));
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled')
          dispatch(showMessage({ severity: 'error', message: e.message }));
      }
    };
    await deleteService();
  };

  let platformsList: JSX.Element[] = [];
  if (project.platforms)
    platformsList = project?.platforms.map(p => (
      <Grid container spacing={1} alignItems="center" key={p.id}>
        <Grid item xs={6}>
          <Typography variant="body1" component="p">
            {p.name}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => onDeletePlatformClick(p.id)}>
            <DeleteForever />
          </Button>
        </Grid>
      </Grid>
    ));

  let serviceList: JSX.Element[] = [];
  if (project.services)
    serviceList = project?.services.map((s, i) => (
      <Grid container spacing={1} alignItems="center" key={i}>
        <Grid item xs={6}>
          <Typography variant="body1" component="p">
            {s}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Button onClick={() => onDeleteServiceClick(s)}>
            <DeleteForever />
          </Button>
        </Grid>
      </Grid>
    ));

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Box
          sx={{
            border: 'solid',
            padding: 1,
            borderWidth: 1,
            borderColor: '#3388e8',
            borderRadius: 1,
            height: '100%',
          }}
        >
          <div>Project Platforms</div>
          <ul>{platformsList}</ul>
        </Box>
      </Grid>
      {project?.services && (
        <Grid item xs={6}>
          <Box
            sx={{
              border: 'solid',
              padding: 1,
              borderWidth: 1,
              borderColor: '#3388e8',
              borderRadius: 1,
              height: '100%',
            }}
          >
            <div>Project Services</div>
            <ul>{serviceList}</ul>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default DeleteProjectPlatform;
