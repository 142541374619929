import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import authReducer from '../entities/auth/model/authSlice';
import alertReducer from '../shared/alert/model/alertSlice';
import propjectSlice from '../entities/project/model/projectSlice';
import userSlice from '../entities/user/model/userSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    alert: alertReducer,
    project: propjectSlice,
    user: userSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
