import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export interface IUser {
  id: string;
  login: string;
  role: string;
}

export interface IUsersState {
  users: IUser[];
}
const initialState: IUsersState = {
  users: [],
};

interface IUpdateState {
  user: IUser;
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUsers: (state, action: PayloadAction<IUsersState>) => {
      state.users = action.payload.users;
    },
    //   prepare(users: IUser[]): { payload: IUsersState } {
    //     return {
    //       payload: {
    //         users: users.map(p => ({ id: p.id, login: p.login, role: p.role.name })),
    //       },
    //     };
    //   },

    // setProject: (state, action: PayloadAction<ISetProject>) => {
    //   const index = state.projects.findIndex(p => p.id === action.payload.project.id);
    //   state.projects[index] = action.payload.project;
    // },
    // updateProject: (state, action: PayloadAction<IUpdateState>) => {
    //   const index = state.projects.findIndex(p => p.id === action.payload.project.id);
    //   state.projects[index] = action.payload.project;
    // },
    // updateBranch: (state, action: PayloadAction<{ branch: string; projectId: string }>) => {
    //   const index = state.projects.findIndex(p => p.id === action.payload.projectId);
    //   state.projects[index].branch = action.payload.branch;
    // },
    // setPipeline: (state, action: PayloadAction<ISetPipeline>) => {
    //   const index = state.projects.findIndex(p => p.id === action.payload.projectId);
    //   state.projects[index].pipeline = action.payload.pipeline;
    // },
    // setPlatforms: (state, action: PayloadAction<ISetPlatforms>) => {
    //   const index = state.projects.findIndex(p => p.id === action.payload.projectId);
    //   state.projects[index].platforms = action.payload.platforms;
    // },
    // deleteProject: (state, action: PayloadAction<string>) => {
    //   state.projects = state.projects.filter(p => p.id !== action.payload);
    // },
    // changeStatus: state => {
    //   state.updated = !state.updated;
    // },
  },
});

export default userSlice.reducer;
export const {
  getUsers,
  //   getProjects,
  //   setPipeline,
  //   setPlatforms,
  //   deleteProject,
  //   changeStatus,
  //   updateBranch,
  //   setProject,
} = userSlice.actions;
export const selectUsers = (state: RootState) => state.user.users;
export const selectUserById = createSelector(
  [selectUsers, (state, userId) => userId],
  (projects, userId) => projects.filter(p => p.id === userId)[0],
);
