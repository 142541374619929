import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buildHttpClient } from './httpClient';
import { getCredentials, selectToken } from '../../entities/auth/model/authSlice';
import { IAuthState } from '../../entities/auth/model/authSlice';

export const useClient = () => {
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const serverClient = buildHttpClient();

  // if (!token) return serverClient;

  const refresh = async () => {
    const { data } = await buildHttpClient().get<IAuthState>('/auth/refresh');
    dispatch(getCredentials(data));
    // dispatch(getCredentials({ accessToken: data.accessToken }));
    return data.accessToken;
  };

  useEffect(() => {
    const requestIntercept = serverClient.interceptors.request.use(
      config => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
      },
      error => {
        Promise.reject(error);
      },
    );

    const responseIntercept = serverClient.interceptors.response.use(
      response => response,
      async error => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401 && !prevRequest?.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          if (!newAccessToken) return Promise.reject(error);
          prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return serverClient(prevRequest);
        }

        return Promise.reject(error);
      },
    );

    return () => {
      serverClient.interceptors.response.eject(requestIntercept);
      serverClient.interceptors.response.eject(responseIntercept);
    };
  });

  return serverClient;
};

export default useClient;
