import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import ButtonAppBar from '../widgets/header/ui/AppBar';
import MessageAlert from '../shared/alert/ui/MessageAlert';
import AppRouter from './AppRouter';
// import AppRouter from '../../app/AppRouter';
// import PersistLogin from '../features/auth/PersistLogin';

function App() {
  return (
    <BrowserRouter>
      <>
        <CssBaseline />
        <MessageAlert />
        <ButtonAppBar />
        <AppRouter />
      </>
    </BrowserRouter>
  );
}

export default App;
