import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../app/store';

export type TAuthUser = {
  id: string;
  login: string;
  role: {
    id: number;
    name: string;
  };
};
export interface IAuthState {
  accessToken?: string | null;
  user?: TAuthUser | null;
}
const initialState: IAuthState = {
  accessToken: null,
  user: null,
};
const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    getCredentials: (state, action: PayloadAction<IAuthState>) => {
      const { accessToken, user } = action.payload;
      if (accessToken) state.accessToken = accessToken;
      if (user) state.user = user;
    },

    logOut: state => {
      state.accessToken = null;
      state.user = null;
    },
  },
});

export default authSlice.reducer;
export const { getCredentials, logOut } = authSlice.actions;
export const selectToken = (state: RootState) => state.auth.accessToken;
export const selectUser = (state: RootState) => state.auth.user;
