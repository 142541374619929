import React, { useEffect, useState } from 'react';

import { Box, Drawer } from '@mui/material';

import RouterList from './RoutesList';

interface ITemporaryDrawer {
  open: boolean;
  toggleDrawer: (event: React.KeyboardEvent | React.MouseEvent) => void;
  setAppBarTitle: React.Dispatch<React.SetStateAction<string>>;
}

export default function TemporaryDrawer({ open, toggleDrawer, setAppBarTitle }: ITemporaryDrawer) {
  const [selectedMenuItem, selectMenuItem] = useState('Projects');
  useEffect(() => {
    setAppBarTitle(selectedMenuItem);
  }, [selectedMenuItem]);
  const list = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
      <RouterList selectMenuItem={selectMenuItem} />
    </Box>
  );

  return (
    <Drawer open={open} onClose={toggleDrawer}>
      {list}
    </Drawer>
  );
}
