import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectToken, IAuthState, getCredentials } from '../../entities/auth/model/authSlice';
import { showMessage } from '../../shared/alert/model/alertSlice';
import { useNavigate } from 'react-router-dom';
import AppPage from '../../shared/ui/page/AppPage';

import { Button, TextField, Box, Typography, Paper, Container } from '@mui/material';
// import { useClient } from '../../shared/api/useClient';
import { buildHttpClient } from '../../shared/api/httpClient';
import { Loading } from '../../shared/ui/loading/Loading';

export default function SignIn(): JSX.Element | null {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const controller = new AbortController();
    const updateToken = async () => {
      const result = await buildHttpClient()
        .get<IAuthState>('/auth/refresh', { signal: controller.signal })
        .catch(e => {
          return;
        });
      if (result?.data) {
        dispatch(getCredentials(result.data));
        navigate('/projects');
      }
      setLoading(false);
    };
    updateToken();

    return () => {
      controller.abort();
    };
  }, []);

  if (loading) return <Loading />;
  // if (error) return <Box>Error</Box>;

  const onSigninSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sendSigninRequest = async () => {
      try {
        setLoading(true);
        if (password && login) {
          const { data } = await buildHttpClient().post<IAuthState>('/auth/signin', { login, password });

          console.log('DATA IN SIGN IN', data);

          dispatch(getCredentials(data));
          navigate('/projects');
        }
      } catch (e) {
        if (e instanceof Error && e.message !== 'canceled') {
          dispatch(showMessage({ severity: 'error', message: e.message }));
          setLoading(false);
          setError(true);
        }
      }
    };
    await sendSigninRequest();
  };

  return (
    <AppPage title="Sign in">
      <Box component="form" onSubmit={onSigninSubmit} noValidate>
        <TextField
          margin="normal"
          required
          fullWidth
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          value={login}
          onChange={event => setLogin(event.target.value)}
        />
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Sign In
        </Button>
      </Box>
    </AppPage>
  );
}
