import { Box, Container, Typography, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectUser } from '../../../entities/auth/model/authSlice';
import AppPage from '../../../shared/ui/page/AppPage';

const Profile = (): JSX.Element | null => {
  const user = useSelector(selectUser);
  if (!user) return null;

  return (
    <AppPage title="Profile">
      <>
        <Typography variant="h6" component="h2">
          {`Login: ${user.login}`}
        </Typography>
        <Typography variant="h6" component="h2">
          {`Role: ${user.role.name}`}
        </Typography>
      </>
    </AppPage>
  );
};

export default Profile;
