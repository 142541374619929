import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';

export const StageSelector = ({
  radioOptions,
  radioChecked,
  setRadioChecked,
}: {
  radioOptions: string[];
  radioChecked: string;
  setRadioChecked: React.Dispatch<any>;
}) => {
  return (
    <FormControl>
      <FormLabel>Select stage</FormLabel>
      <RadioGroup value={radioChecked} onChange={e => setRadioChecked(e.target.value)}>
        <FormControlLabel value={radioOptions[0]} control={<Radio />} label={radioOptions[0]} />
        <FormControlLabel value={radioOptions[1]} control={<Radio />} label={radioOptions[1]} />
      </RadioGroup>
    </FormControl>
  );
};
