import { Box, TextField, Button } from '@mui/material';
import AppPage from '../../../shared/ui/page/AppPage';
import { useEffect, useState } from 'react';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import Selector, { IOption } from '../../../shared/ui/autocomplete/Autocomplete';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useClient from '../../../shared/api/useClient';
import { buildHttpClient } from '../../../shared/api/httpClient';
import { useLoader } from '../../../shared/api/useLoader';
import { Loading } from '../../../shared/ui/loading/Loading';

export interface IRole {
  id: number;
  name: string;
}

const AddUser = () => {
  const [roles, setRoles] = useState<IRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<null | IOption>(null);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const client = useClient();
  const navigate = useNavigate();

  const onAddUserSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      if (!(selectedRole && password && login)) {
        dispatch(showMessage({ severity: 'error', message: 'All required fields not filled in' }));
        return;
      }

      const body = { login, password, roleId: selectedRole?.id };

      await client.post('/users', body);
      setLogin('');
      setPassword('');
      dispatch(showMessage({ severity: 'success', message: 'User added' }));
      navigate('/users');
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  const { loading, error, data } = useLoader<IRole[]>(`/roles`);

  useEffect(() => {
    if (data) setRoles(data);
  }, [data, loading]);

  if (loading) return <Loading />;
  if (error) return <Box>Error</Box>;

  return (
    <AppPage title="Add User">
      <Box component="form" onSubmit={onAddUserSubmit} noValidate>
        <TextField
          required
          margin="normal"
          fullWidth
          id="login"
          label="Login"
          name="login"
          autoComplete="login"
          autoFocus
          value={login}
          onChange={event => setLogin(event.target.value)}
        />
        <TextField
          required
          margin="normal"
          fullWidth
          name="password"
          label="Password"
          id="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
        />
        <Selector
          options={roles.map(r => ({ id: r.id, title: r.name }))}
          selectedValue={selectedRole}
          setSelectedValue={setSelectedRole}
          label="Roles"
        />
        <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
          Add
        </Button>
      </Box>
    </AppPage>
  );
};

export default AddUser;
