import { useDispatch } from 'react-redux';
import SharedComponents from '../../../shared/ui';

import { deleteProject } from '../../../entities/project/model/projectSlice';
import { showMessage } from '../../../shared/alert/model/alertSlice';
import useClient from '../../../shared/api/useClient';


export const DeleteProjectButton = ({ projectId }: { projectId: string }) => {
  const dispatch = useDispatch();
  const client = useClient();

  const onDeleteClick = async () => {
    try {
      await client.delete(`/projects/${projectId}`);
      dispatch(deleteProject(projectId));
      dispatch(showMessage({ severity: 'success', message: 'success' }));
    } catch (e) {
      if (e instanceof Error && e.message !== 'canceled')
        dispatch(showMessage({ severity: 'error', message: e.message }));
    }
  };

  return <SharedComponents.CustomButton style="outlined" onClick={() => onDeleteClick()} content="Delete" />;
};
