import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { hideMessage, selectMessage, selectSeverity, selectShow } from '../model/alertSlice';
import { Alert } from '@mui/material';

const MessageAlert = (): JSX.Element | null => {
  const show = useSelector(selectShow);
  const severity = useSelector(selectSeverity);
  const message = useSelector(selectMessage);
  const dispatch = useDispatch();

  useEffect(() => {
    if (show) setTimeout(() => dispatch(hideMessage()), 2000);
  }, [show]);

  if (!show) return null;

  return (
    <Alert severity={severity} sx={{ position: 'absolute', right: 0, top: 40 }}>
      {message}
    </Alert>
  );
};

export default MessageAlert;
