import { IProject, getProjects, selectProjects } from '../../../entities/project/model/projectSlice';
import { useLoader } from '../../../shared/api/useLoader';
import SharedComponents from '../../../shared/ui';
import { THeader, TRow } from '../../../shared/ui/types';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { EditProject } from '../../../features/project';
import { DeleteProjectButton } from '../../../features/project';

// DeleteProjectButton

const constructProjectRow = (project: IProject, isAdmin: boolean): TRow => {
  const row: TRow = [
    {
      content: (
        <Link component={RouterLink} to={`/projects/${project.id}`}>
          {project.id}
        </Link>
      ),
    },
    { content: project.name },
    { content: project.branch },
  ];

  if (isAdmin) {
    row.push({ content: <EditProject projectId={project.id} /> });
    row.push({ content: <DeleteProjectButton projectId={project.id} /> });
  }

  return row;
};

// export type TProjectRow = { id: string; name: string; triggerToken: 0 | 1 };

export const ProjectsTable = ({
  projects,
  isAdmin,
}: {
  projects: IProject[];
  isAdmin: boolean;
}): JSX.Element => {
  const headers: THeader = [{ name: 'Id' }, { name: 'Name' }, { name: 'Branch' }];

  if (isAdmin) {
    headers.push({ name: 'Trigger token/ref' });
    headers.push({ name: 'Delete' });
  }

  const rows: TRow[] = projects.map(p => constructProjectRow(p, isAdmin));
  return <SharedComponents.CustomTable container="div" headers={headers} rows={rows} />;
};
