import { Box, Container, Typography, Paper, useMediaQuery } from '@mui/material';
import { Children } from 'react';

interface IAppPageProps {
  title: string;
  children: JSX.Element;
}

const AppPage = ({ title, children }: IAppPageProps) => {
  const overflow = useMediaQuery('(max-height:900px)') ? 'auto' : 'none';

  return (
    <Container maxWidth="lg" sx={{ height: '80%' }}>
      <Paper sx={{ padding: '16px', mt: 5, height: '100%' }}>
        <Typography variant="h5" component="h2">
          {title}
        </Typography>
        <Box sx={{ mt: '16px', height: '90%', overflow }}>{children}</Box>
      </Paper>
    </Container>
  );
};

export default AppPage;
